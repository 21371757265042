import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import data from "./resources/cardInfo.json";
import {
  FaHeadphonesAlt,
  FaBook,
  FaCode,
  FaRegWindowMaximize,
  FaEnvelope,
  FaGithub,
  FaKey
} from "react-icons/fa";
import { CardColumns, Card, Button } from "react-bootstrap";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <CardColumns>{generateCards()}</CardColumns>
        </div>
      </header>
      <footer className="App-footer">
        <div>
          <a
            className="App-link"
            href="mailto:kforkl@kforkl.com?Subject=WebsiteForm"
            target="_top"
          >
            <FaEnvelope />
          </a>{" "}
          <a
            className="App-link"
            href="https://keybase.io/yorick"
            target="_top"
          >
            <FaKey />
          </a>{" "}
          <a
            className="App-link"
            href="https://github.com/AlasPoorYorick"
            target="_top"
          >
            <FaGithub />
          </a>
        </div>
      </footer>
    </div>
  );
}

function generateCards() {
  return data.cards.map(card => (
    <Card bg="dark">
      <Card.Header>{card.CardHeader}</Card.Header>
      <Card.Body>
        <Card.Text>{card.CardText}</Card.Text>
        <Button variant="primary" href={card.ButtonLink}>
          {generateIcon(card.ButtonType)} {card.ButtonType}
        </Button>
      </Card.Body>
    </Card>
  ));
}

function generateIcon(type) {
  switch (type) {
    case "Author":
    case "Book":
      return <FaBook />;
    case "Blog":
      return <FaRegWindowMaximize />;
    case "Listen":
    case "Podcast":
      return <FaHeadphonesAlt />;
    case "Code":
      return <FaCode />;
    default:
      return "";
  }
}

export default App;
